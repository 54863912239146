.metrics-container {
  width: 500px;
  height: fit-content;
  position: absolute;
  right: 266px;
  bottom: 8px;
  text-align: right;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.compass-icon {
  display: inline-flex;
  margin-bottom: 12px;
  & svg {
    overflow: visible;
    width: 1.25em;
    height: 1.25em;
    top: 0.25em;
    position: relative;
  }
}

.distance-text {
  margin-bottom: -5px;
  text-align: right;
}

.heart-text {
  margin-bottom: -5px;
  text-align: right;
}

.heading-text {
  margin-bottom: -5px;
  text-align: right;
}

.speed-text {
  margin-bottom: -5px;
  text-align: right;
}

.altitude-text {
  margin-bottom: -5px;
  text-align: right;
}
