@import url('https://fonts.googleapis.com/css2?family=Anek+Latin&family=Blinker&family=Questrial&display=swap');

body {
  overflow: hidden;
  // background-color: black;
}

.App {
  overflow: hidden;
  position: absolute;
  width: 100vw;
  height: 100vh;
}
