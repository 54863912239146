.neighbourhood-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  right: 10px;
  top: -4px;
  text-align: right;

  font-family: 'Blinker', sans-serif;

  color: #fff;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  will-change: transform;
  transition: transform 0.1s linear;
}

.neighbourhood {
  display: inline-block;
  text-align: center;
  position: inline-flex;
  width: fit-content;
  font-size: 24px;
}
