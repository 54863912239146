.time-container {
  display: inline-flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-family: 'Questrial', sans-serif;
  font-size: 20px;
  color: #ffffff;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.time {
  padding-bottom: 5px;
  padding-top: 5px;
  font-size: 20px;
  // width: 1em;
}
