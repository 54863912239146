.map-container {
  position: absolute;
  width: 256px;
  height: fit-content;
  bottom: 5px;
  right: 5px;
}

.marker {
  position: absolute;
  background-color: cyan;
  height: 15px;
  width: 15px;
  position: absolute;
  border-radius: 50%;
  top: 47%;
  left: 47%;
  box-shadow: 0 0 10px cyan;
  z-index: 400;
}

.leaflet-container {
  width: 256px;
  height: 256px;
}
